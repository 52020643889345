.title {
    font-family: "Raleway";
    font-weight: 600;
    line-height: 21px;
    color: #222222;
  }
  
  .locationDetailsChart {
    width: 50%;
    height: 390px;
    overflow-y: scroll;
  }
  
  
  .locationDetailsChart::-webkit-scrollbar {
    width: 8px;
  }
  
  .locationDetailsChart::-webkit-scrollbar-track {
    background: #efefef;
  }
  
  .locationDetailsChart::-webkit-scrollbar-thumb {
    background-color: #109cf1;
    border-radius: 5px;
  }
  