@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");

.switch {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 16px !important;
}

.switch input {
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
}

.slider {
  position: absolute !important;
  cursor: pointer !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-color: #ccc !important;
  -webkit-transition: 0.4s !important;
  transition: 0.4s !important;
}

.slider:before {
  position: absolute !important;
  content: "" !important;
  height: 18px !important;
  width: 18px !important;
  left: 4px !important;
  bottom: -1px !important;
  background-color: white !important;
  transition: 0.4s !important;
  border: 1px solid #53a451;
}

.switch input:checked + .slider {
  background-color: #53a451 !important;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #53a451 !important;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(18px) !important;
  -ms-transform: translateX(18px) !important;
  transform: translateX(18px) !important;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px !important;
}

.slider.round:before {
  border-radius: 50% !important;
}

.volume-controls-container {
  min-width: 200px;
  max-width: 100%;
}

a:hover {
  text-decoration: none;
}

.offersMainHeading {
  font-weight: bold;
  font-size: 22px;
  padding: 30px 0;
  color: #222222;
}

.makeOffer-btnGroup-active {
  cursor: pointer;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 500;
  background: #53a451;
  color: white;
  transition: 0.5s;
  border: none;
  outline: none;
  height: 44px;
  border-radius: 4px;
}

.makeOffer-btnGroup-inactive {
  cursor: pointer;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 500;
  background: transparent;
  color: grey;
  transition: 0.5s;
  border: none;
  outline: none;
  height: 44px;
}

p {
  /* font-family: "Raleway", sans-serif !important;*/
  font-family: "DM Sans", DM Sans !important;
}

.block-p {
  background: #f6f7fa;
  padding: 33px 90px 27px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.block-p p {
  margin: 0;
}

.label-font {
  font-size: 16px !important;
  color: #222222 !important;
}

select.form-control:not([size]):not([multiple]) {
  border-radius: 2px;
  border-color: #cccccc !important;
  height: 45px;
  font-size: 16px !important;
  color: #999999 !important;
  padding: 5px 17px !important;
}

.custom-font {
  padding: 9px 15px;
  font-size: 16px;
  color: #999999;
  height: 44px;
}

.pt-14 {
  padding-top: 14px;
}

.radio-btn {
  display: inline;
  margin-right: 36px;
}

.radio-btn Label span {
  color: #999999;
  margin-bottom: 8px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-8 {
  margin-bottom: 8px;
}

.radio-btn Label input:checked ~ * {
  color: #222222;
}

.input-area {
  height: 88px !important;
  border-radius: 5px;
}

.agent {
  padding: 54px 0 8px;
}

.download {
  margin-bottom: 0;
}

.download img {
  width: 10px;
  cursor: pointer;
}

.letter Label {
  margin-right: 68px !important;
}

.make-offer-heading {
  font-size: 18px;
  font-weight: 600;
  margin-top: 30px;
}

.download-details img {
  margin-right: 3px;
  margin-bottom: 2px;
}

.download Label,
.download-details Label {
  font-size: 14px;
  text-decoration: underline;
  color: #222222;
  margin-right: 15px;
  min-width: 250px;
}

.download Button {
  color: #222222;
  font-size: 14px;
  font-weight: 500;
}

.video-icon {
  width: 18px;
  height: 12px;
  margin-right: 11px;
}

.mic-icon {
  width: 14px;
  height: 19px;
  margin-right: 13px;
}

.button-with-icon {
  border: 1px solid #222222;
  color: #222222;
  background-color: white;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  padding: 8px 19px;
  cursor: pointer;
  margin-right: 16px;
  display: flex;
  align-items: center;
}

.delete-file-btn {
  border: 1px solid #d94b57;
  color: #d94b57;
  margin-top: 8px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-bottom: 0;
  margin-top: 3px;
  /* margin-top: 23px; */
}

.video-upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-bottom: 0;
  margin-top: 3px;
}

.custom-form-group {
  height: 44px !important;
  margin-bottom: 0 !important;
}

.video-recorder-modal-body {
  margin: 0 !important;
}

.video-recorder-container {
  padding: 0 !important;
}

.video-recorder-frame {
  height: 435px;
  width: 100%;
}
/* 
.video-recorder-frame-container video {
  height: 435px !important;
  width: 100% !important;
} */

.preview-video-frame {
  width: 490px;
  max-width: 100%;
  height: 223px;
}

.record-Video-heading {
  color: #222222;
  font-size: 22px;
  font-weight: 700;
  font-family: "Raleway";
  margin-top: -22px;
  margin-left: 16px;
  margin-bottom: 18px;
  width: fit-content;
}

.range-slider-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.range-slider-container img {
  margin-right: 7px;
  width: 15.67px;
  height: 20.33px;
}

.ml-30 {
  margin-left: 30px;
}

.row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.record-video-action-container {
  padding: 14px 20px 20px;
}

._1lB9c {
  display: none !important;
}

._2fG9h {
  padding: 0 !important;
}

._1ceqH ._3bC73 {
  margin-bottom: 75px;
}

._37kfa {
  background: #53a451 !important;
  border: 1px solid #53a451 !important;
  border-radius: 8px !important;
  padding: 8px 22px !important;
}

._37kfa:hover {
  background: white !important;
  color: #53a451 !important;
  border-radius: 8px !important;
  padding: 8px 22px !important;
  border: 1px solid #53a451 !important;
}

._2gd2_ {
  border-radius: 8px !important;
  border: 1px solid white !important;
  padding: 8px 22px !important;
  color: black !important;
}

._2gd2_:hover {
  background: #53a451 !important;
  border: 1px solid #53a451 !important;
  border-radius: 8px !important;
  padding: 8px 22px !important;
  color: white !important;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 80px;
  height: 7px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  background-image: linear-gradient(#53a451, #53a451);
  background-repeat: no-repeat;
}

/* Input Thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: white;
  /* cursor: ew-resize; */
  -moz-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
  border: 2px solid #53a451;
  margin-top: -5px;
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: white;
  cursor: ew-resize;
  -moz-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
  border: 2px solid #53a451;
}

input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: white;
  cursor: ew-resize;
  -moz-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
  border: 2px solid #53a451;
}

input[type="range"]::-webkit-slider-thumb:hover {
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb:hover {
  cursor: pointer;
}

input[type="range"]::-ms-thumb:hover {
  cursor: pointer;
}

/* Input Track */
input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: #53a451;
  border-radius: 5px;
  background-image: linear-gradient(#53a451, #53a451);
  background-repeat: no-repeat;
  height: 7px;
}

input[type="range"]::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: #53a451;
}

input[type="range"]::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: #53a451;
}

.restart-button {
  color: #53a451;
  border: 1px solid #53a451;
  background-color: white;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 24px;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 0;
}

.finish-recording-btn {
  border: 1px solid #53a451;
  color: white;
  background-color: #53a451;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 19px;
  cursor: pointer;
  display: flex;
  margin-bottom: 0;
  align-items: center;
}

.finish-recording-btn img {
  width: 17.59px;
  height: 13.41px;
  margin-right: 10px;
}

.play-pause-btn {
  border: 2px solid #ffd4dd;
  background-color: #fff4f6;
  cursor: pointer;
  margin: 0;
  height: 51px;
  width: 51px;
  border-radius: 50%;
}

.custom-form-group-upload {
  margin-bottom: 0 !important;
}

.play-pause-btn img {
  width: 20px;
  height: 23px;
}

.video-upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-bottom: 0;
  margin-top: 3px;
}

.offer-video-img {
  width: 23px;
  margin-left: 8px;
}

.offer-audio-img {
  width: 30px;
  margin-left: 8px;
}

.flex-start-align-center {
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center !important;
}

.custom-form-group {
  height: 44px !important;
  margin-bottom: 0 !important;
}

.video-recorder-modal-body {
  margin: 0 !important;
}

.video-recorder-container {
  padding: 0 !important;
}

.video-recorder-frame {
  height: 435px;
  width: 100%;
}
/* 
.video-recorder-frame-container video {
  height: 435px !important;
  width: 100% !important;
} */

.preview-video-frame {
  width: 490px;
  max-width: 100%;
  height: 223px;
}

.record-Video-heading {
  color: #222222;
  font-size: 22px;
  font-weight: 700;
  font-family: "Raleway";
  margin-top: -22px;
  margin-left: 16px;
  margin-bottom: 18px;
  width: fit-content;
}

.range-slider-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.range-slider-container img {
  margin-right: 7px;
  width: 15.67px;
  height: 20.33px;
}

.ml-30 {
  margin-left: 30px;
}

.row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.record-video-action-container {
  padding: 14px 20px 20px;
}

._1lB9c {
  display: none !important;
}

._2fG9h {
  padding: 0 !important;
}

._1ceqH ._3bC73 {
  margin-bottom: 75px;
}

._37kfa {
  background: #53a451 !important;
  border: 1px solid #53a451 !important;
  border-radius: 8px !important;
  padding: 8px 22px !important;
}

._37kfa:hover {
  background: white !important;
  color: #53a451 !important;
  border-radius: 8px !important;
  padding: 8px 22px !important;
  border: 1px solid #53a451 !important;
}

._2gd2_ {
  border-radius: 8px !important;
  border: 1px solid white !important;
  padding: 8px 22px !important;
  color: black !important;
}

._2gd2_:hover {
  background: #53a451 !important;
  border: 1px solid #53a451 !important;
  border-radius: 8px !important;
  padding: 8px 22px !important;
  color: white !important;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 80px;
  height: 7px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  background-image: linear-gradient(#53a451, #53a451);
  background-repeat: no-repeat;
}

/* Input Thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: white;
  /* cursor: ew-resize; */
  -moz-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
  border: 2px solid #53a451;
  margin-top: -5px;
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: white;
  cursor: ew-resize;
  -moz-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
  border: 2px solid #53a451;
}

input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: white;
  cursor: ew-resize;
  -moz-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
  border: 2px solid #53a451;
}

input[type="range"]::-webkit-slider-thumb:hover {
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb:hover {
  cursor: pointer;
}

input[type="range"]::-ms-thumb:hover {
  cursor: pointer;
}

/* Input Track */
input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: #53a451;
  border-radius: 5px;
  background-image: linear-gradient(#53a451, #53a451);
  background-repeat: no-repeat;
  height: 7px;
}

input[type="range"]::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: #53a451;
}

input[type="range"]::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: #53a451;
}

.restart-button {
  color: #53a451;
  border: 1px solid #53a451;
  background-color: white;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 24px;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 0;
}

.finish-recording-btn {
  border: 1px solid #53a451;
  color: white;
  background-color: #53a451;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 19px;
  cursor: pointer;
  display: flex;
  margin-bottom: 0;
  align-items: center;
}

.finish-recording-btn img {
  width: 17.59px;
  height: 13.41px;
  margin-right: 10px;
}

.play-pause-btn {
  border: 2px solid #ffd4dd;
  background-color: #fff4f6;
  cursor: pointer;
  margin: 0;
  height: 51px;
  width: 51px;
  border-radius: 50%;
}

.custom-form-group-upload {
  margin-bottom: 0 !important;
}

.play-pause-btn img {
  width: 20px;
  height: 23px;
}

.upload-btn {
  border: 1px solid #222222;
  color: #222222;
  background-color: white;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  padding: 8px 25px;
  cursor: pointer;
  margin-top: 3px;
}

.view-email-btn {
  background-color: transparent;
  border: none;
  color: #4eaddd;
  font-size: 12px;
}

.reply-btn {
  background: transparent;
  border: 1px solid lightgray;
  font-size: 12px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 5px 10px;
}

.center-btn {
  background: transparent;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  border-left: none;
  border-right: none;
  font-size: 12px;
  padding: 5px 10px;
}

.compose-email-btn {
  background: transparent;
  border: 1px solid lightgray;
  font-size: 12px;
  padding: 5px 15px;
  border-radius: 20px;
}

.forward-btn {
  background: transparent;
  border: 1px solid lightgray;
  font-size: 12px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 5px 10px;
}

.email-from {
  font-size: 14px;
  font-weight: bold;
}

.email-to {
  font-size: 14px;
}

.email-container {
  border-left: 1px solid rgb(215, 222, 226);
  padding-left: 30px;
}

.email-divider-btn {
  background-color: rgb(129, 210, 255);
  border: none;
  width: 22px;
  height: 22px;
  font-size: 8px;
  border-radius: 50%;
  color: white;
  position: absolute;
  left: 22px;
  text-align: center;
}

.email-divider-icon {
  width: auto !important;
  height: auto !important;
}

.email-caret-right {
  width: 20px;
  height: 20px;
}

.dashboard-content .tab-content {
  width: 100% !important;
}

.offer-package > .nav-tabs .nav-link {
  border: 1px solid transparent !important;
  text-align: center !important;
  width: 120px !important;
}

.offer-package .nav {
  margin: 0px !important;
  background-color: white !important;
}

.ql-container {
  min-height: 10rem;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ql-editor {
  min-height: 300px !important;
  flex: 1;
  overflow-y: auto;
  width: 100%;
}

.email-replies-container {
  border: 1px solid rgb(215, 222, 226);
  background: rgb(252, 253, 253);
  padding: 10px;
  border-radius: 5px;
  color: #4eaddd;
}

.email-replies-view-container {
  border: 1px solid rgb(215, 222, 226);
  background: rgb(252, 253, 253);
  padding: 10px;
  border-radius: 5px;
}

.email-replies-text {
  color: #4eaddd;
  font-size: 14px;
}

.email-replies-content {
  color: #222222;
  font-size: 14px;
}
.truncated-text {
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Add ellipsis (...) when text overflows */
}

.email-subject-input {
  width: 90% !important;
  border: none !important;
}

.email-subject-label {
  font-size: 14px !important;
}

.add-attachment-label {
  font-size: 14px !important;
}

.pointer {
  cursor: pointer !important;
}

.attachment-item {
  background-color: rgb(232, 236, 240);
  border: 1px solid rgb(232, 236, 240);
  border-radius: 13px;
  padding: 6px 10px;
  font-size: 12px;
}

.attachment-name {
  color: #4eaddd;
}

.compose-emai-section {
  border: 1px solid rgb(215, 222, 226);
  /* border: 1px solid black; */
  border-radius: 10px !important;
}

.email-tooltip {
  background-color: #fff !important;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2) !important;
}

.email-tooltip > .tooltip-inner {
  background-color: #fff !important;
  font-size: 12px !important;
  max-width: 1000px !important;
  width: 250px !important;
  text-align: left !important;
}

.bs-tooltip-bottom > .arrow {
  display: none;
}

.font-12 {
  font-size: 12px !important;
}

.overflow-scroll {
  overflow-x: scroll !important;
}

.overflow-scroll::-webkit-scrollbar {
  display: none;
}

.upload-btn-active {
  border: 1px solid #53a451;
  color: #fff;
  background-color: #53a451;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  padding: 8px 25px;
  cursor: pointer;
  margin-top: 3px;
}

.start-recording-btn {
  background-color: #fff4f6;
  border: 1px solid #ffd9e1;
  border-radius: 30px;
  font-size: 16px;
  margin-bottom: 0;
  cursor: pointer;
  color: #ff214f;
  font-weight: 500;
  font-family: "Raleway";
  padding: 8px 16px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.start-recording-btn img {
  height: 28px;
  width: 28px;
  margin-right: 16px;
}

.upload-icon {
  margin-right: 13px;
  width: 14px;
  height: 17px;
}

.video-upload-btn-wrapper input[type="file"],
.upload-btn-wrapper input[type="file"] {
  display: none;
}

.submit-btns {
  margin-top: 32px;
  margin-bottom: 54px;
}

.submit-offer {
  cursor: pointer;
  width: 276px;
  padding: 0;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  transition: 0.5s;
  border-radius: 5px;
  border: none;
  outline: none;
  border: 1px solid #53a451;
  background-color: #53a451;
  margin-right: 20px;
  margin-bottom: 15px;
  height: 44px;
}

.submit-offer-seller-text {
  position: absolute;
  width: 200px;
  background-color: #fef;
  left: 39px;
  bottom: 60px;
  border: 1px solid red;
  padding: 10px;
  border-radius: 5px;
}
.submit-offer-seller-image {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  width: 50px;
  position: absolute;
  bottom: 55px;
  left: 0px;
  rotate: -15deg;
}

.submit-offer:disabled {
  border: 1px solid #81b280;
  background-color: #81b280;
}

.submit-offer-seller {
  border: 1px dotted red !important;
  font-size: 12px !important;
  cursor: default;
}

.submit-offer:hover {
  background-color: white;
  color: #53a451;
  border: 1px solid #53a451;
}

.preview-mail-btn {
  cursor: pointer;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 500;
  color: #222222;
  border: 1px solid #222222;
  transition: 0.5s;
  border-radius: 5px;
  outline: none;
  background-color: white;
  width: 276px;
  height: 44px;
}

.preview-mail-btn:hover .preview-link {
  color: white;
}

.preview-mail-btn:hover {
  color: white;
  background-color: #222222;
}

.preview-link:hover {
  color: white;
}

.cancel-request {
  cursor: pointer;
  padding: 0;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 500;
  color: #d94b57;
  border: 1px solid #d94b57;
  transition: 0.5s;
  border-radius: 5px;
  outline: none;
  background-color: white;
  width: 276px;
  height: 44px;
  margin-right: 20px;
  margin-bottom: 15px;
}
.cancel-request:hover {
  color: white;
  background-color: #d94b57;
}

.underline {
  text-decoration: underline;
}

.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 10px 0 0;
}

.radio-item input[type="radio"] {
  display: none;
}

.radio-item span {
  color: #666;
  font-weight: normal;
}
/* 
.radio-item span:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid #cccccc;
  background-color: transparent;
}

.radio-item Input[type="radio"]:checked + span:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: " ";
  display: block;
  background: #017c42;
}
 */

input[type="radio"]:not(.thank) {
  visibility: hidden;
}

input[type="radio"]:not(.thank):after {
  width: 25px;
  height: 25px;
  top: -8px;
  left: -8px;
  position: relative;
  content: "";
  display: inline-block;
  visibility: visible;
  background-image: url("../icons/offers-radio-unchecked.svg");
}

input[type="radio"]:not(.thank):checked:after {
  width: 30px;
  height: 30px;
  top: -10px;
  left: -10px;
  position: relative;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
  background-image: url("../icons/offers-radio-checked.svg");
}

.thank {
  height: 8px !important;
}

.nav-tabs {
  border: 0;
}

.padd-lft {
  padding-left: 15px;
}

.notification-bell {
  border-radius: 0 !important;
  width: 24px !important;
  height: 29px !important;
  margin-top: 29px !important;
}

.notification-list ul li {
  margin-left: 0;
  border-bottom: 1px solid #efe7e7;
  width: 100%;
}

.notification-list ul li p {
  margin: 0;
  font-size: 10px !important;
  text-align: right;
  padding-right: 16px;
  color: #9a9a9a;
  cursor: pointer;
  margin-top: -10px;
}

.notification-list ul li:hover {
  background-color: #f3f4f7;
}

.empty-notification ul li p {
  font-size: 16px !important;
  margin-top: 0 !important;
  min-height: 50px;
  padding-top: 10px;
  text-align: left;
  padding-left: 10px;
}

.empty-notification ul li p:hover {
  background-color: white !important;
}

.dropdown-item {
  font-size: 12px !important;
}

.dropdown-item:focus {
  background-color: white;
}

.main-header::-webkit-scrollbar,
.dropdown-menu::-webkit-scrollbar {
  width: 4px !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
  border-radius: 16px !important;
}

.notification-badge {
  background-color: #d94b57;
  color: white;
  font-size: 14px !important;
  margin-top: -54px;
  width: 22px;
  height: 22px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  margin-left: 16px;
}

.notification-width {
  left: -265px;
  position: absolute;
  width: 300px;
  overflow: hidden;
  background: white;
  top: 84px;
}

.notification-width a {
  max-width: 300px;
  white-space: normal;
}

.content-box-signupCheck {
  color: #7c7c7c;
  text-align: center;
  margin: 0 !important;
  box-shadow: none !important;
  margin-bottom: 40px !important;
  max-width: inherit !important;
}

.yes-btn {
  background-color: #28a745;
  border-radius: 3px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 20px;
  border: 0;
  padding: 5px 55px 4px;
  cursor: pointer;
  margin-right: 15px;
}

.content-box-signupCheck p {
  font-size: 16px !important;
  color: grey;
}

.content-box-signupCheck a {
  font-weight: 600;
  font-size: 12px !important;
}

.no-offer-found {
  text-align: center;
  margin: 100px 0;
  font-weight: 500;
}

.single-checkbox label {
  padding-left: 6px;
  margin: 5px 0 !important;
  cursor: pointer;
}

.single-checkbox input[type="checkbox"]:checked + label:before {
  display: none;
}

.mb-40 {
  margin-bottom: 40px;
}

.drag-and-drop-container {
  display: flex;
  flex-direction: row;
  margin: 0 0 30px;
  padding: 10px;
  background: #ebecf0;
  border-radius: 4px;
}

.follow-my-listing-icon {
  max-width: 30px;
}

.label-sms-blast {
  text-align: left;
}

.label-sms-blast label {
  margin: 5px !important;
}

.gnerate-file {
  width: 156px;
  border-radius: 3px;
  color: white;
  font-weight: 500;
  font-size: 14px !important;
  border: 0;
  cursor: pointer;
  height: 31px;
  background: green;
  margin-right: 15px;
}

[data-rbd-draggable-id] {
  left: auto !important;
  top: auto !important;
}

.concent {
  padding: 20px 0 8px;
}

.comment-container {
  flex-direction: row !important;
}

.date-picker-partioned-form {
  width: 100%;
}

.content-box-preview-offer {
  box-shadow: none;
  margin: 20px 0;
}

.preview-offer-score-tooggle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.preview-offer-score-tooggle label {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.powered-by-offerAIde {
  color: #ff214f;
  font-weight: bold;
}

.total-scr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: bold;
}

.real-time-scr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  padding-top: 8px;
}

.powered-by-offeraide-sm-txt {
  text-align: center;
  font-size: 12px;
  margin-top: 20px;
  color: #6c757d;
}

.preview-offer-score-modal-head h5 {
  font-size: 18px;
  font-weight: bold;
}

.gotit-btn {
  cursor: pointer;
  padding: 0;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 500;
  color: #28a745;
  border: 2px solid #28a745;
  transition: 0.5s;
  border-radius: 5px;
  outline: none;
  background-color: white;
  width: 100px;
  height: 36px;
}

.gotit-btn:hover {
  color: white;
  background-color: #28a745;
}

.submit-anyway-btn {
  cursor: pointer;
  padding: 0;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 500;
  color: #666666;
  border: 2px solid #666666;
  transition: 0.5s;
  border-radius: 5px;
  outline: none;
  background-color: white;
  height: 36px;
  padding: 0 16px;
}

.submit-anyway-btn:hover {
  color: white;
  background-color: #666666;
}

.ai-score-widget-container {
  height: 150px;
  width: 150px;
  position: fixed;
  top: 10px;
  right: 25px;
}

.ai-score-widget-container canvas {
  height: 150px !important;
  width: 150px !important;
  border-radius: 150px;
  background-color: white;
}
.doughnat-score {
  margin: 0 auto;
  font-family: "Poppins";
  font-size: 32px;
  font-weight: 600;
  color: #222222;
  line-height: 38px;
  letter-spacing: 0.01rem;
}

.learn-scoring-button-make-offer {
  margin-bottom: 0;
  border: 0;
  background: transparent;
  color: #8b8b8b;
  font-weight: bold;
  font-size: 15px;
  margin-top: 10px;
  cursor: pointer;
}

@media (max-width: 1209px) {
  .custom-form-group-upload {
    margin-bottom: 1rem !important;
  }
  .video-upload-btn-wrapper {
    margin-top: 0;
  }
  .makeOffer-btnGroup-active,
  .makeOffer-btnGroup-inactive {
    height: auto;
  }
}

@media (max-width: 450px) {
  .comment-container {
    flex-direction: column !important;
  }

  .comment-btn-otherAgent {
    margin-top: 28px !important;
  }

  .submit-offer-seller-text {
    bottom: 120px !important;
  }

  .submit-offer-seller-image {
    bottom: 115px !important;
  }

  .record-video-action-container {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }
  .play-pause-btn {
    margin-bottom: 20px;
  }
  .button-with-icon,
  .upload-btn {
    min-width: 190px;
  }
  .range-slider-container {
    width: 100%;
    justify-content: center;
  }
  ._1ceqH ._1Yplu {
    text-align: center;
  }
  ._1ceqH ._3bC73 {
    margin-bottom: 0 !important;
  }
  ._1ceqH ._3bC73 ._1YOWG {
    position: relative !important;
    left: 0 !important;
    -webkit-transform: 0 !important;
    transform: 0 !important;
    bottom: 0 !important;
    text-align: center !important;
    display: inline;
  }
  ._qxztz {
    transform: translate(-45%) !important;
  }
  .block-p {
    padding: 16px;
  }
  .mobile-pl-0 {
    padding-left: 0;
  }
  .upload-btn-wrapper {
    margin-left: 0 !important;
  }
  .ai-score-widget-container canvas {
    height: 110px !important;
    width: 110px !important;
    border-radius: 110px;
  }
  .ai-score-widget-container {
    height: 110px;
    width: 110px;
    right: 10px;
  }
  .doughnat-score {
    font-size: 25px;
    line-height: 25px;
  }
  .makeOffer-btnGroup-active, .makeOffer-btnGroup-inactive {
    font-size: 15px;
    font-weight: 600;
  }
  .sub-banner {
    height: 200px !important;
}
}

@media (max-width: 1078px) {
  .mt-95 {
    margin-top: 60px;
  }
}

.comment-box {
  height: 44px !important;
  margin-top: 8px !important;
}
