.main-container {
  background-color: #fff;
  padding: 20px 10px 20px 10px;
}

.secondary-container {
  background-color: #f6f7fa;
  padding: 20px 10px 20px 10px;
}

.mobile-export-btn {
  background: #fff;
  color: #109cf1;
  /* opacity: 1; */
  text-transform: none;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #ebebeb;
  margin-bottom: 0px !important;
  width: 32% !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-view-details-btn {
  background: #fff;
  color: #109cf1;
  /* opacity: 1; */
  text-transform: none;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #ebebeb;
  margin-bottom: 15px !important;
  /* width: 110px !important; */
  width: auto !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-view-offer-btn {
  background: #109cf1;
  color: #ffffff;
  border-radius: 4px;
  border: none;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0px !important;
  width: 100% !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-btns-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.mobile-summary-details {
  height: 40px !important;
}

.mobile-summary-details button {
  align-items: center !important;
  width: 50% !important;
  margin-bottom: 0px !important;
  justify-content: center !important;
}

.mobile-active-details {
  padding: 6px 9px !important;
}

.mobile-verified-container {
  background: #f8f8f8;
  border-radius: 4px;
  padding: 20px 20px;
  border: 1px solid #ebebeb;
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  color: #222222;
}

.mobile-verified-container button {
  background-color: rgb(16, 156, 241);
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 4px;
  font-size: 12px;
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin-bottom: 0px !important;
}

.mobile-analytics-filters-modal {
  background-color: #f6f7fa;
  border-radius: 4px;
  padding: 20px 20px;
  border: 1px solid #ebebeb;
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  color: #222222;
  height: 100%;
  width: 100%;
}

.mobile-filters-cancel-btn {
  background: #fff;
  color: #109cf1;
  /* opacity: 1; */
  text-transform: none;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #ebebeb;
  margin-bottom: 0px !important;
  width: 100% !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-global-data-btn {
  background: #ebebeb;
  color: #109cf1;
  /* opacity: 1; */
  text-transform: none;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #ebebeb;
  margin-bottom: 0px !important;
  width: 100% !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-filters-footer-container {
  background-color: #fff;
  padding: 20px 10px 10px 10px;
  /* margin-top: 59vh !important; */
  margin-top: calc(100vh - 369px) !important;
}

.mobile-global-data-modal {
  background-color: #f6f7fa;
  border-radius: 4px;
  padding: 20px 20px;
  border: 1px solid #ebebeb;
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  color: #222222;
  /* height: 100%; */
  width: 100%;
}

.mob-common-close-btn-container button {
  margin-bottom: 0px !important;
}

.mob-global-offers-modal-container {
  height: 256px !important;
}

.thankme-modal {
  min-width: 350px !important;
}

.global-my-data-btn {
  background: #ededed;
  color: #109cf1;
  /* border-radius: 4px; */
  border: none;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0px !important;
  width: 100% !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  margin-bottom: 1px !important;
}

.global-global-btn {
  background: #ededed;
  color: #109cf1;
  /* opacity: 1; */
  text-transform: none;
  /* border-radius: 4px; */
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #ebebeb;
  margin-bottom: 0px !important;
  width: 100% !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.global-cancel-btn {
  background: #fff;
  color: #000;
  /* opacity: 1; */
  text-transform: none;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid lightgray;
  margin-bottom: 0px !important;
  width: 100% !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.global-my-active-btn {
  background: #109cf1;
  color: #ededed;
  /* opacity: 1; */
  text-transform: none;
  /* border-radius: 4px; */
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #109cf1;
  margin-bottom: 0px !important;
  width: 100% !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.global-data-modal-mobile .modal-content {
  background-color: transparent !important;
  border: none !important;
}

.thank-you-modal {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.mobile-icons {
  width: 16px !important;
  height: 16px !important;
}

.mob-filters-container span {
  color: #999999 !important;
}

.thankme-date-input {
  height: 42px !important;
}

.thankme-modal-back-btn {
  font-size: 10px;
  text-decoration: underline;
  cursor: pointer;
}
.mob-filter-date-picker-icon {
  margin-right: 5px !important;
}
.global-data-heading{
  width: auto !important;
}

@media screen and (max-width: 576px) {
  .mob-locationDetailsChart .chartjs-render-monitor {
    animation: chartjs-render-animation 0.001s;
    width: 100% !important;
    height: 300px !important;
  }

  .modal:has(.thankme-modal) {
    position: fixed !important;
    top: auto !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }

  .thankme-modal {
    min-width: 0px !important;
  }
}
