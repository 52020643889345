.title {
  font-family: "Raleway";
  font-weight: 600;
  line-height: 21px;
  color: #222222;
}

.locationDetailsChart {
  width: 49%;
  height: 350px;
}

.locationDetailsChart::-webkit-scrollbar {
  width: 8px;
}

.locationDetailsChart::-webkit-scrollbar-track {
  background: #efefef;
}

.locationDetailsChart::-webkit-scrollbar-thumb {
  background-color: #109cf1;
  border-radius: 5px;
}

.mob-locationDetailsChart {
  width: 100%;
  height: 350px;
}

.mob-locationDetailsChart::-webkit-scrollbar {
  width: 8px;
}

.mob-locationDetailsChart::-webkit-scrollbar-track {
  background: #efefef;
}

.mob-locationDetailsChart::-webkit-scrollbar-thumb {
  background-color: #109cf1;
  border-radius: 5px;
}

@media screen and (max-width: 576px) {

}
