
p {
  font-family: "DM Sans", DM Sans !important;
}

.property-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}

.main-col {
  padding-top: 30px;
  width: 70%;
}

.side-col {
  padding-top: 30px;
  width: 28%;
}

.property-container {
  width: 100vw !important;
  padding: 0 59px;
  display: flex;
  justify-content: center;
  background: #f6f7fa;
}

.property-main {
  max-width: 1400px;
  /* width: 1400px; */
  min-width: 60%;
}

.image-gallery-image {
  height: calc(100vh - 80px) !important;
}

.image-gallery-slide .image-gallery-image {
  object-fit: cover !important;
}

.share-home-btn {
  background-color: transparent;
  border: 0;
  cursor: pointer !important;
  margin-top: 20px;
  width: fit-content;
}

.share-home-btn:hover {
  background-color: transparent;
  text-decoration: underline;
  border: 0;
}

.share-home-btn:focus,
.share-home-btn:focus-visible,
.share-home-btn:active {
  background-color: transparent !important;
  text-decoration: underline !important;
}

.share-home-btn img {
  height: 19px;
  margin-right: 8px;
}

.view-trend-offers-btn {
  background: transparent;
  border: 0;
  color: white;
  cursor: pointer;
  width: fit-content;
}

.view-trend-offers-btn img:not(.global-chart-trend-img) {
  height: 16px !important;
  margin-top: -8px;
  margin-right: 8px;
}

.view-trend-offers-btn:focus,
.view-trend-offers-btn:focus-visible,
.view-trend-offers-btn:active,
.view-trend-offers-btn:hover {
  text-decoration: underline !important;
}

.retailor-container {
  background-color: white;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
}

.realtorInfo-modal-body {
  margin: 20px !important;
  font-family: "DM Sans", DM Sans;
}

.property-card-padding {
  padding: 40px 30px 24px;
}

.represented-by-realor {
  padding: 30px 20px;
  border: 1px solid #eee;
  background-color: white;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
}

.image-gallery-slide .image-gallery-image {
  width: auto !important;
}

.image-gallery-slide {
  position: absolute;
  top: auto !important;
  width: auto !important;
  margin: 0 !important;
  background: white !important;
}

.trend-modal-heading {
  font-size: 24px;
  font-weight: 600;
  color: black;
}

.share-link-icon {
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
}

.trend-modal-heading img {
  margin-left: 8px;
  margin-top: -6px;
}

.trend-modal-subheading {
  color: black;
  font-weight: 400;
  margin-top: 16px;
  margin-bottom: 24px;
}

.light-bold-td {
  font-size: 18px !important;
  font-weight: 400;
  color: #222222;
  text-align: right;
  font-family: "DM Sans", DM Sans !important;
}

.light-weight {
  font-weight: 400 !important;
  text-transform: capitalize;
}

.m-vertical-32 {
  margin: 32px 0;
}

.progress-bar {
  background-color: #1c95d9 !important;
}

.progress {
  border-radius: 30px;
  width: 102%;
  margin-left: -10px;
}

.progress-value {
  font-weight: 500;
  font-size: 18px !important;
  margin: 0;
  color: #222222;
}

.row-spacebtwn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.verified-offers-text {
  color: #222222;
  font-weight: 400;
  margin: 0;
  text-transform: capitalize;
  margin-bottom: 5px;
}

/* .lissting-price-container {
  padding-top: 0 !important;
} */

.offer-trend-status {
  color: #222222;
  font-size: 16px !important;
  margin: 5px 0 0;
}

.offer-trend-status span {
  font-weight: bold;
}

@media (max-width: 1100px) {
  .property-row {
    flex-direction: column;
  }

  .main-col {
    width: 100%;
  }

  .side-col {
    margin-top: 30px;
    width: 100%;
  }
}

.property-info-box {
  margin-bottom: 10;
  background-color: #2a2a2a;
  padding: 45px 20px 20px 20px;
  font-size: 14;
  min-height: 86%;
  font-family: "DM Sans", DM Sans;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  color: white;
}

.details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.details-item {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 10px;
}

@media (max-width: 880px) {
  .details {
    grid-template-columns: 1fr;
  }
}

.amenities-card {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 50px;
  grid-row-gap: 20px;
}

.amenities-icon {
  margin-right: 20px;
}

.amenity {
  font-weight: bold;
}

.overview-card {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 50px;
  grid-row-gap: 20px;
}

.overview-item {
  display: flex;
  flex-direction: row;
}

.overview-icon {
  background-color: #f7f7f7;
  padding: 10px;
  color: #999999;
  margin-right: 10px;
  width: 54px;
}

.livin-area {
  padding: 5px;
  width: 54px;
}

.dining-areas {
  height: 54px;
}

.overview-item-info {
  display: flex;
  flex-direction: column;
}

.btn--link {
  width: auto;
  border-radius: 4px;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  opacity: 1;
  transition: opacity ease 0.3s;
}

.btn--link:hover {
  opacity: 0.7;
}

.btn[disabled] {
  opacity: 0.7;
  cursor: unset;
  pointer-events: none;
}

.btn--link-block {
  border: white 2px;
}

.card {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: none;
}

.card-header {
  border: none;
  background-color: inherit;
  font-weight: bold;
  padding: 0;
  padding-bottom: 30px;
  font-size: 20px;
}

@media (max-width: 768px) {
  .property-container {
    padding: 0 20px;
    width: 100vw !important;
  }

  .overview-card {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 500px) {
  .overview-card {
    grid-template-columns: 1fr;
  }

  .amenities-card {
    grid-template-columns: 1fr;
  }
}

.blog-video {
  width: 100%;
  aspect-ratio: 16 / 9;
}
.tooltip-inner {
  max-width: 300px !important;
  padding: 12px 6px !important;
}
