.analyticOne {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  font-family: "Raleway";
}

.MuiBox-root .MuiBox-root-16 {
  padding: 0 !important;
}

.analyticTitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 18px 0 20px;
  background-color: #ffffff;
}

.overviewLinks a {
  padding-left: 15px;
  color: #727272;
  font-size: 12px;
}

.chartBlock {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.oneChart {
  display: flex;
  width: 100%;
  gap: 15px;
}

.totalOffersListings {
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.oneChart > div {
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.DoughuntChartBlock {
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.verifyOffers {
  width: 196px;
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f8f8f8;
  justify-content: space-between;
  border-radius: 4px;
  padding: 20px 20px;
  border: 1px solid #ebebeb;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #222222;
  padding-left: 20px !important;
}

#Order_Location {
  /*padding-right:20px;*/
}

.go-btn {
  position: absolute;
  right: 0;
  top: 0;
}

.summary_span > span {
  color: #ffffff !important;
  line-height: 14px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

.summary_span > span:focus {
  color: white !important;
}

.overviewButton {
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 14px;
  background: #ebebeb;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  line-height: 30px;
}
/*
.light>span {
  background-color: #109CF1;
  color: #fff !important;
  line-height: 14px;
  font-size: 12px;
  color: white !important;
  height: 24px;
  align-items: center;
}

.dark>span {
  background-color: #EBEBEB;
  color: #727272 !important;
  line-height: 14px;
  font-size: 12px;
  color: white !important;
  height: 24px;
  align-items: center;
} */

.activeDetails {
  background-color: #109cf1;
  color: #ffffff;
  padding: 3px 9px;
  font-weight: 600;
}

.deActiveDetails {
  background: unset;
  text-transform: none;
  padding: 2px 10px;
  font-weight: 500;
  text-align: center;
  color: #727272;
  font-weight: 500;
}

.summaryDetails > button {
  border-radius: 2px;
  border: none;
  font-size: 12px;
}

.summaryDetails {
  background: #f8f8f8;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  display: flex;
  padding: 4px;
  column-gap: 4px;
  font-size: 12px;
}
a {
  color: #727272;
}

.summaryDetailsBlock {
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  padding: 4px;
  flex: none;
  flex-grow: 0;
  align-items: center;
}

.summaryDetailsBlock button {
  display: flex;
  align-items: center;
}

.bokeragesSelect {
  height: 40px !important;
  padding: 8px;
  width: 139.5px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 4px;
}

.analyticTitle input {
  height: 41px !important;
}

.dateRange {
  border: none;
  padding: 5px 10px;
  height: 30px;
  width: 150px;
}

.dateRangeTitle {
  position: absolute;
  top: 8px;
  background: #ffffff;
  width: 118px;
  text-align: center;
  color: #999999;
  font-size: 12px !important;
  padding-right: 27px;
  height: 23px;
  left: 6px;
  z-index: 1;
  font-weight: 500;
}

.priceSelect {
  height: 40px !important;
  padding: 8px;
  width: 139.5px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 4px;
}

.tabs a {
  background: unset !important;
  border: none !important;
}

.tabs {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.tabs a:hover,
.tabs a:focus,
.customTabActive a:first-child,
.customTabActive1 a:nth-child(2),
.customTabActive2 a:nth-child(3),
.customTabActive3 a:nth-child(4) {
  color: rgba(16, 156, 241, 1) !important;
  border: none !important;
  border-bottom: 2px solid rgba(16, 156, 241, 1) !important;
}

.globalData {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
}

.width15 {
  background: #ffffff;
  max-height: 34px;
  border-radius: 4px;
  width: 160px;
}
.width17 {
  background: #ffffff;
  max-height: 34px;
  border-radius: 4px;
  width: 175px;
}
.width15 button {
  border-radius: 4px;
}

.width180 {
  width: 210px;
}

.width15dateRange {
  position: relative;
  border: 1px solid #ebebeb;
  border-radius: 4px;
}

.width15dateRange input {
  height: 32px;
  padding-bottom: 4px;
  border-radius: 4px;
}
.width15dateRange input::placeholder {
  color: #999999;
  font-size: 12px;
}

.exportButton {
  background: #fff;
  color: #109cf1;
  opacity: 0.5;
  text-transform: none;
  padding: 9px 16px;
  border-radius: 4px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  border-radius: 4px;
  max-height: 32px;
  font-weight: 600;
  border: 1px solid #ebebeb;
}

.exportParent {
  width: 10%;
  border: none;
  display: flex;
  align-items: center;
  border: 1px solid #ebebeb;
  background: #ffffff;
  height: calc(100% - 10px);
  column-gap: 42px;
}

.viewOffers {
  background: #109cf1;
  color: #ffffff;
  width: 100px;
  text-transform: none;
  padding: 9px 16px;
  border-radius: 4px;
  border: none;
  font-size: 12px;
  max-height: 32px;
  display: flex;
  align-items: center;
  font-weight: 600;
  justify-content: center;
}

.tabsParent {
  border-bottom: 1;
  border-color: divider;
}
.tabsParent a {
  padding: 5px 1rem;
  padding-left: 10px;
  padding-right: 10px;
}

.locationImg {
  position: absolute;
  top: 28%;
  right: 6%;
  color: #999999;
  width: 13px;
}

.dropdownStyle {
  background: white;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  width: 100%;
  max-width: 240px;
}

.dropdownStyle button {
  color: #999999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  font-size: 12px;
  margin: 0;
  background: unset;
  font-weight: 500;
}

.dropdownStyle img {
  width: 9px;
  margin-left: 4px;
}

.details {
  color: #999999;
}

.locationInput {
  height: 41px;
  width: 140px;
  border: none;
  border-radius: 4px;
  text-indent: 16px;
}

.locationInput::placeholder {
  font-size: 14px;
  font-weight: 500;
}

.dateRangBlock {
  position: relative;
  float: left;
  border-radius: 4px;
}

.calendarBtn {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 25px;
  height: 25px;
  background: #fff;
  pointer-events: none;
}

.calendarBtn button {
  border: none;
  background: transparent;
}

.line {
  height: 32px;
  background: #ebebeb;
  width: 1px;
}
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #222222;
  margin-bottom: 0;
}
.wrapper {
  display: flex;
  align-items: baseline;
  gap: 8px;
}
.btnImg {
  border: none;
  background-color: #fff;
}
.btnImg img {
  min-height: 34px;
}

.vector {
  align-self: flex-start;
  display: block;
  margin-bottom: 16px;
}

.ratioDetails {
  width: 100%;
  background: #ffffff;
}

.ratioDetails::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.ratioDetails::-webkit-scrollbar-track {
  background: #efefef;
}

.ratioDetails::-webkit-scrollbar-thumb {
  background-color: #109cf1;
  border-radius: 5px;
}

.range {
  display: none;
}

.rangeDisplay {
  position: absolute;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #999999;
  top: 10px;
  left: 10px;
}

.labels {
  display: flex;
  align-items: center;
  column-gap: 3px;
  font-family: "Raleway";
  font-weight: 500;
  font-size: 12px;
  color: #727272;
}

.circle, .circle1 {
  width: 17px;
  border-radius: 10px;
  height: 17px;
  margin-left: 7px;
}

.circle:first-child, .circle1:first-child {
  background: #28a745;
}

.circle:nth-child(3) {
  background: red;
}

.circle:nth-child(5) {
  background: #ffc107;
}


.circle1:nth-child(3) {
  background: #109CF1;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.globalRatioDetails {
  min-height: 450px;
}
