.chartContainer {
  position: relative;
}

.chartContainer::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid black;
  pointer-events: none;
}

.labels {
  display: flex;
  align-items: center;
  column-gap: 3px;
  font-family: "Raleway";
  font-weight: 500;
  font-size: 12px;
  color: #727272;
  margin-top: 10px;
}

.circle,
.circle2,
.circle4 {
  width: 17px;
  border-radius: 10px;
  height: 17px;
  margin-left: 12px;
}

.circle:first-child {
  background: #28a745;
}

.circle:nth-child(3) {
  background: #109cf1;
}

.circle2:first-child {
  background: #EBEBEB;
}

.circle2:nth-child(5) {
  background: #109cf1;
}

.circle2:nth-child(3) {
  background: #28a745;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.circle4:first-child {
  background: #EBEBEB;
}

.circle4:nth-child(3) {
  background: #28a745;
}

.circle4:nth-child(5) {
  background: #109cf1;
}
