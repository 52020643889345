.container {
  padding: 20px;
  margin: 30px 0;
  background-color: #fff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.container > ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.container > ::-webkit-scrollbar-track {
  background: #efefef;
}

.container > ::-webkit-scrollbar-thumb {
  background-color: #109cf1;
  border-radius: 5px;
}
