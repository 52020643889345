.dropdown button {
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-weight: 400;
  font-size: 14px;
  background: unset;
  padding-left: 6px;
  border: none;
  margin: 0;
  padding-top: 6px;
  cursor: pointer;
}

.container {
  padding: 20px;
  background: #fff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 20px;
}

.container > ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.container > ::-webkit-scrollbar-track {
  background: #efefef;
}

.container > ::-webkit-scrollbar-thumb {
  background-color: #109cf1;
  border-radius: 5px;
}
