.globalPriceRatio {
  width: 100%;
  overflow-x: auto;
}
.globalPriceRatio::-webkit-scrollbar {
  height: 8px;
}

.globalPriceRatio::-webkit-scrollbar-track {
  background: #efefef;
}

.globalPriceRatio::-webkit-scrollbar-thumb {
  background-color: #109cf1;
  border-radius: 2px;
}

.roundDiagrams div {
  /* gap: inherit !important;
  column-gap: 45px !important; */
  /* padding: 0 !important; */
}

.roundDiagrams {
  padding: 70px 0 25px 0;
}

.labels {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  column-gap: 4px;
  font-family: "Raleway";
  font-weight: 500;
  font-size: 12px;
  color: #727272;
}

.circle, .circle3 {
  width: 17px;
  border-radius: 10px;
  height: 17px;
}

.circle:first-child {
  background: #28a745;
}

.circle:nth-child(3) {
  background: #109CF1;
  margin-left: 12px;
}

.circle:nth-child(5) {
  background: #FFC107;
  margin-left: 12px;
}

.circle3:first-child {
  background: #28a745;
}

.circle3:nth-child(3) {
  background: #EB3E54;
  margin-left: 18px;
}

.circle3:nth-child(5) {
  background: #FFC107;
  margin-left: 18px;
}

.text-decoration-line-through {
  text-decoration: line-through;
}
